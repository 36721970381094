import { Injector } from '@angular/core';
import { AuthService } from './auth/auth.service';

export function appInitializerFactory(injector: Injector) {
  return () => {
    return new Promise<boolean>((resolve, reject) => {
      const authService = injector.get(AuthService);

      authService.init().then(result => {
        if (!result) authService.logout();
        resolve(true);
      }).catch(() => {
        authService.logout();
        resolve(true);
      });
    });
  };
}
